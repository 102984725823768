.mainList, .nestedList {
    counter-reset: item;
}

.mainOrderedItem, .nestedOrderedItem {
    list-style-position: inside;
    display: block;

}

.mainOrderedItem::before, .nestedOrderedItem::before {
    content: counters(item, ".") " ";
    counter-increment: item;
}

.nestedList {
    margin-top: 20px;
}

.mainList span {
    font-weight: bold;
}

.mainOrderedItem {
    margin-bottom: 20px;
}

.nestedOrderedItem, .nestedUnorderedItem {
    margin-bottom: 7px;
}

.aboutPolicy {
    margin-top: 20px;
}

.nestedUnorderedList {
    margin-top: 7px;
    list-style-position: inside;
    list-style-type: '—';
    padding-left: 15px;
}

.address {
    margin-top: 20px;
}

@media screen and (max-width: 1050px) {
    .privacyPolicyTitle {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 768px) {
    .privacyPolicyTitle {
        font-size: 1.2em;
    }

    .nestedUnorderedList, .nestedList {
        padding-left: 10px;
    }

    .mainList {
        padding: 0;
        font-size: 14px;
    }
}
