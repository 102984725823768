@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
  --white:          #ffffff;
  --black:          #333333;
  --greyFA:         #FAFBFC;
  --greyF5:         #F5F6FA;
  --greyFAFB:       #FAFBFC;
  --greyD4:         #d4d4d4;
  --greyC4:         #c4c4c4;
  --greyEF:         #EFEFEF;
  --greyE1:         #e1e8ed;
  --grey8A:         #8A92A6;
  --blue_light:     #81a9ff5e;
  --blue:           #1559ed;
  --blue_hover:     #3c4bdc;
  --blue_active:    #659ef3;
  --red:            #d90909;
  --red_hover:      #DC3E22;
  --red_active:     #B7280F;
  --orange:         #f16a1b;
  --green:          #20b820;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--greyF5);
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

body::-webkit-scrollbar {
  width: 9px;               /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
  background: rgb(196, 193, 193);        /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
  background-color: var(--blue);    /* цвет плашки */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--blue_hover); 
  transition: 0.4s;
}

* {
  color: var(--black);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  font-family: inherit;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}
