.popup {
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 20;
}

.nodeEnter {
    opacity: 0;
  }
  .nodeEnterActive {
    opacity: 1;
    transition: opacity 300ms;
  }
  .nodeExit {
    opacity: 1;
  }
  .nodeExitActive {
    opacity: 0;
    transition: opacity 300ms;
  }