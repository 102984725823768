.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.popupBody {
  position: relative;
}

.nodeEnter {
    opacity: 0;
    transform: scale(0.9);
  }
  .nodeEnterActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;

  }
  .nodeExit {
    opacity: 1;
  }
  .nodeExitActive {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }