.auth {
    height: 100dvh;
    width: 100%;
    background-color: var(--white);
    display: flex;
}

.authFormBlock {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.authFormBody {
    text-align: center;
    position: relative;
}

.authBackground {
    width: 50%;
    overflow: hidden;
}

.authBackgroundImg {
    animation: animScale 45s 1s ease-in-out infinite;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.errorBlock {
    margin-bottom: 15px;
}

.errorText {
    color: var(--red);
    font-size: 14px;
}

@keyframes animScale {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    50% {
        -webkit-transform: scale(1.175);
        transform: scale(1.175);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.authFormBody {
    position: relative;
    width: 60%;
    padding: 0 20px;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 25px;
    margin-bottom: 1rem;
    width: fit-content;
}

.logo svg {
    margin-right: 15px;
}

.authTitle {
    font-size: 2rem;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 0.5rem;
}

.authText {
    font-size: 1rem;
    line-height: 1.2;
    color: var(--grey8A);
    margin: 0;
}

.authForm {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.authForm .inputBlock:not(:first-child) {
    margin-bottom: 25px;
}

.inputBlock {
    position: relative;
}

.changeTypeIcon {
    cursor: pointer;
}

.inputBlock svg {
    position: absolute;
    top: 36px;
    right: 10px;
}

.changeTypeIcon:hover svg path {
    stroke: var(--blue);
    transition: 0.4s;
}

.authFormInput {
    width: 100%;
    color: var(--black);
    margin-top: 25px;
    padding: 10px 40px 10px 10px;
    border: 1px solid #eee;
    border-radius: 7px;
    outline: none;
    font-size: 1rem;
}

.authFormInput:focus {
    border-color: var(--blue);
}

input[type='password'] {
    letter-spacing: 0.07em;
}

.authFormInput::placeholder {
    letter-spacing: 0;
}

.privacyPolicy {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
}

.privacyPolicyLink {
    text-decoration: underline;
}

.privacyPolicyLink:hover {
    color: var(--blue);
    transition: 0.4s;
}

.authFormButton {
    font-size: 1rem;
    color: var(--white);
    padding: 12px 30px;
    background-color: var(--blue);
    border-radius: 5px;
}

.authFormButton:hover {
    background-color: var(--blue_hover);
    transition: 0.4s;
}

.spinnerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    width: 20px;
    height: 20px;
    border: 2px solid var(--white);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 

@media screen and (max-width: 1200px) {
    .authFormBody {
        width: 80%;
    }
}

@media screen and (max-width: 1050px) {
    .authTitle {
        font-size: 1.5rem;
    }
    
    .authText {
        font-size: 0.8rem;
    }

    .logo {
        font-size: 20px;
    }

    .logo svg {
        width: 25px;
        height: 25px;
    }
}

@media screen and (max-width: 900px) {
    .authFormBody {
        width: 90%;
    }

    .authTitle {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 768px) {
    .authBackground {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .authFormBlock {
        z-index: 2;
        width: 100%;
    }

    .authFormBody {
        width: 90%;
        background: rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12px);
        -webkit-backdrop-filter: blur(12px);
        padding: 30px;
    }

    .authTitle, .authText, .privacyPolicyLink, .logo {
        color: var(--white);
    }

    .privacyPolicyLink:hover {
        color: var(--white);
    }

    .logo svg path {
        fill: var(--white);
    }

    .authFormInput {
        color: var(--white);
        border: none;
        background: rgba(0, 0, 0, 0.15);
    }

    .authFormInput::placeholder {
        font-size: 14px;
        color: var(--white);
    }

    .authFormButton {
        background: rgba(0, 0, 0, 0.09);
    }

    .changeTypeIcon svg path {
        stroke: var(--white);
    }

    .changeTypeIcon:hover svg path {
        stroke: var(--white);
    }

    .errorBlock {
        padding: 10px;
        border-radius: 7px;
        background: rgba(0, 0, 0, 0.15);
    }

    .errorText {
        font-size: 12px;
        color: var(--white);
    }
}

@media screen and (max-width: 450px) {
    .authTitle {
        font-size: 1.2rem;
    }

    .authText {
        font-size: 0.7rem;
    }
}