.privacyPolicyBody {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    position: relative;
    max-width: 1000px;
    min-width: 500px;
}

.contentWrapper {
    padding: 30px 30px 0 30px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background: #FFFFFF;
}

.privacyPolicyContent {
    overflow-y: auto;
    padding-right: 20px;
    max-height: 85vh;
}

.closeButton {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    background-color: var(--blue);
    color: var(--white);
    font-size: 16px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.closeButton:hover {
    background-color: var(--blue_hover);
    transition: 0.4s;
}

.privacyPolicyContent::-webkit-scrollbar {
    width: 5px;               /* ширина scrollbar */
}

.privacyPolicyContent::-webkit-scrollbar-track {
    background: rgb(196, 193, 193);        /* цвет дорожки */
}

.privacyPolicyContent::-webkit-scrollbar-thumb {
    background-color: var(--blue);    /* цвет плашки */
}

@media screen and (max-width: 1200px) {
    .privacyPolicyBody {
        width: 80vw;
    }
}

@media screen and (max-width: 768px) {
    .privacyPolicyContent {
        height: 60vh;
    }

    .contentWrapper {
        padding: 20px 20px 0 20px;
    }

    .privacyPolicyBody {
        min-width: 80vw;
    }
}

@media screen and (max-width: 450px) {
    .privacyPolicyBody {
        min-width: 90vw;
    }

    .contentWrapper {
        padding: 15px 15px 0 15px;
    }
}